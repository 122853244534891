<template>
  <b-card>
    <usuarioInterno-list
      ref="usuarioInternoList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :remover="remover"
      :filtros="filtros"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <div class="d-block">
          <span
            class="editable-column"
            @click="editar(props.row)"
          >
            {{ props.row[props.column.field] }}
            <b-badge
              v-if="props.row['status'] == 'aguardandoconfirmacao'"
              variant="light-warning"
              class="ml-1 ml-auto"
              style="padding: 0.2rem 0.2rem;font-size: 10px;"
            >
              Aguardando
            </b-badge>
            <b-badge
              v-if="props.row['status'] == 'ativo'"
              variant="light-success"
              class="ml-1 ml-auto"
              style="padding: 0.2rem 0.2rem;font-size: 10px;"
            >
              Confirmado
            </b-badge>
            <b-badge
              v-if="props.row['contador'] == true"
              class="ml-1 ml-auto"
              style="background-color: rgba(108, 77, 159, 0.12); color: #120985; margin-left: 10px !important; padding: 0.2rem 0.2rem;font-size: 10px;"
            >
              Contador
            </b-badge>
          </span>
        </div>
      </template>
      <template
        slot="acoesFinal"
        slot-scope="{ props} "
      >
        <acoes
          :grid-props="props"
        />
      </template>
    </usuarioInterno-list>
    <!-- <b-modal
      id="m-vincularEmpresas"
      size="lg"
      hide-footer
    >
      <template #modal-title>
        Vincular Empresas
      </template>
      <div>
        <vinculo-empresas />
        <b-row class="mb-2" />
        <b-card-footer>
          <b-row no-gutters>
            <b-col class="text-right">
              <b-btn
                variant="none"
                class="btn-outline-success mr-50"
                @click="$bvModal.hide('m-vincularEmpresas')"
              >Salvar</b-btn>
            </b-col>
            <b-btn
              variant="none"
              class="btn-outline-danger"
              @click="$bvModal.hide('m-vincularEmpresas')"
            >Cancelar</b-btn>
          </b-row>
        </b-card-footer>
      </div>
    </b-modal>
    <b-modal
      id="m-configurarPermissoes"
      size="xl"
      hide-footer
    >
      <template #modal-title>
        Configurar Permissões
      </template>
      <div>
        <configurar-permissoes />
        <b-row class="mb-2" />
        <b-card-footer>
          <b-row no-gutters>
            <b-col class="text-right">
              <b-btn
                variant="none"
                class="btn-outline-success mr-50"
                @click="salvarPermissoes()"
              >Salvar</b-btn>
            </b-col>
            <b-btn
              variant="none"
              class="btn-outline-danger"
              @click="cancelarPermissoes()"
            >Cancelar</b-btn>
          </b-row>
        </b-card-footer>
      </div></b-modal>
    <b-modal
      id="m-cancelarPermissoes"
      size="xs"
      hide-footer
    >
      <template #modal-title />
      <div>
        <b-row class="mb-2" />
        <h5>Tem certeza de que deseja cancelar as alterações?</h5>
        <b-card-footer>
          <b-row no-gutters>
            <b-col class="text-right">
              <b-btn
                variant="none"
                class="btn-outline-success mr-50"
                @click="$bvModal.hide('m-cancelarPermissoes'), $bvModal.hide('m-configurarPermissoes')"
              >Sim</b-btn>
            </b-col>
            <b-btn
              variant="none"
              class="btn-outline-danger"
              @click="$bvModal.hide('m-cancelarPermissoes')"
            >Não</b-btn>
          </b-row>
        </b-card-footer>
      </div></b-modal> -->
  </b-card>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.usuario-td-acao {
  width: 9rem;
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}
</style>

<script>

import api from '@/app/usuario/shared/services/api'
import colunas from '@/app/usuario/shared/components/colunas'
// import VinculoEmpresas from '@/app/usuario/shared/components/vinculo-empresas/VinculoEmpresas.vue'
// import ConfigurarPermissoes from '@/app/usuario/shared/components/configurar-permissões/ConfigurarPermissoes.vue'

export default {
  name: 'UsuarioInternoView',
  components: {
    UsuarioInternoList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    // VinculoEmpresas,
    // ConfigurarPermissoes,
  },
  data() {
    return {
      api,
      active: false,
      botoesOption: {
        NotificarLimite: false,
        LimiteMensagem: 'Limite de cadastro do plano atingido. Será enviado um e-mail com as informações do novo cadastro para melhor controle de sua base.',
        novo: {
          evento: this.novo,
        },
        editar: {
          evento: this.editar,
        },
        remover: {
          evento: this.remover,
        },
      },
      visualizacao: {
        placeholderBusca: 'Buscar por Nome ou Login',
        acaoClass: 'usuario-td-acao',
      },
      filtros: [{ colunas: ['Nome', 'Login'], valor: null }],
    }
  },
  created() {
    this.verificarLimite()
    this.visualizacao.colunas = colunas
    this.visualizacao.colunas[0].evento = this.editar
    this.visualizacao.evento = this.carregar
  },
  methods: {
    show() {
      this.active = !this.active
    },
    verificarLimite() {
      return new Promise((resolve, reject) => {
        api.getLimiteCadastros()
          .then(payload => {
            this.botoesOption.NotificarLimite = payload.data
            resolve(payload.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    novo() {
      this.$router.push({ name: 'usuario-novo' })
    },
    editar(value) {
      this.$router.push({ name: 'usuario-editar', params: { id: value.id } })
    },
    remover(linhas) {
      const msgErro = linhas && linhas.length > 0 ? 'Não foi possível excluir um ou mais usuário' : `Não foi possível o usuário${linhas[0].nome}`
      const ids = linhas && linhas.length > 0 ? linhas.map(m => m.id) : linhas[0].id
      return new Promise((resolve, reject) => {
        api.removerUsuarios(ids)
          .then(payload => {
            if (payload.data === false) {
              reject(new Error(msgErro))
            }
            resolve(payload.data)
          })
          .catch(err => {
            reject(new Error(msgErro, err))
          })
      })
    },
    salvarPermissoes() {
      return new Promise((resolve, reject) => {
        api.updateUsuarioInterno(this.form.id, this.form)
          .then(() => {
            resolve('Permissões salvas com sucesso!')
            this.$bvModal.hide('m-configurarPermissoes')
          })
          .catch(err => {
            reject(err)
            this.$bvModal.hide('m-configurarPermissoes')
          })
      })
    },

    cancelarPermissoes() {
      this.$bvModal.show('m-cancelarPermissoes')
    },
  },
}
</script>
