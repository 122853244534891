import axios from '@axios'

const PREFIX = '/usuarios'

export default {

  salvarUsuario(usuario) {
    return axios.post(`${PREFIX}`, usuario)
  },

  alterarUsuario(id, usuario) {
    return axios.put(`${PREFIX}/${id}`, usuario)
  },

  removerUsuarios(ids) {
    if ((Array.isArray(ids) && ids.length > 1)) {
      return axios.delete(`${PREFIX}/lote?valores=[${ids}]`)
    }
    return axios.delete(`${PREFIX}/${ids}`)
  },

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getUsuario(id) {
    return axios.get(`${PREFIX}/${id}`)
  },

  getLimiteCadastros() {
    return axios.get(`${PREFIX}/buscar-limite`)
  },

  getUsuariosResumidos(filtro, ativos) {
    return axios.post(`${PREFIX}/resumido?ativo=${ativos}`, filtro)
  },

  getEmpresasPorUsuario(id, filtro) {
    return axios.post(`${PREFIX}/${id}/empresas?`, filtro)
  },

  existeCpf(cpf, id) {
    if (id) {
      return axios.get(`${PREFIX}/${id}/existe?cpf=${cpf}`)
    }
    return axios.get(`${PREFIX}/existe?cpf=${cpf}`)
  },

  existeLogin(login, id) {
    if (id) {
      return axios.get(`${PREFIX}/${id}/existe?login=${login}`)
    }
    return axios.get(`${PREFIX}/existe?login=${login}`)
  },
  enviarConvite(id) {
    return axios.get(`${PREFIX}/${id}/convidar`)
  },
  getEmpresasParaVinculo() {
    return axios.get(`${PREFIX}/empresas`)
  },
}
